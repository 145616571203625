import React from 'react';
import './css/Commercial.css';

import ShantaiClassicPhase2 from './assets/image/building/shantaiclassic.avif'
import ResidentialBanner from './assets/image/resi-bg.avif'

function ResUpcoming() {
    return (
        <div>
            {/* About Us Banner Start */}
            <div className='about-banner'>
                <img width={'100%'} height={'100%'} src={ResidentialBanner}/>
                <div className='banner-path'>
                    <h1>Upcoming Projects</h1>
                    <p><a href='/home'>Home</a> &#8594; <a href='/projects'>Projects</a> &#8594; Upcoming Projects</p>
                </div>
            </div>
            {/* About Us Banner End */}

            {/* Upcoming Projects */}
            <div className='project-status'>
                <h1>Upcoming Project</h1>
            </div>
            <div className='comm-projects'>
                <div className='projects'>
                <a href='/projects/upcoming/shantai_classic_phase_2' className='project-img project-card'>
                        <img width={'100%'} height={'100%'} src={ShantaiClassicPhase2} className='image project-image' />
                        <div className='project-img-over project-card-info'>
                            {/* <h1 className='text'>Residential Projects</h1> */}
                            <h1>Shantai Classic<br/>Phase 2</h1>
                            <p>Ravet</p>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default ResUpcoming;
