import React, { useState } from 'react';
import './css/About.css';
import { HashLoader } from 'react-spinners';

import AboutBanner from './assets/image/img_banner.avif'
import ValidCheck from './assets/image/check.avif'

function Contact() {
    // Form Operations
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [desc, setDesc] = useState('')
    const [status, setStatus] = useState('FAILED')

    const [nameAlert, setNameAlert] = useState('alert-hide')
    const [phoneAlert, setPhoneAlert] = useState('alert-hide')
    const [invalidPhoneAlert, setInvalidPhoneAlert] = useState('alert-hide')
    const [descAlert, setDescAlert] = useState('alert-hide')

    const [formData, setFormData] = useState({
        timeStamp: new Date().toLocaleString(),
        fullName: '',
        phoneNumber: '',
        enquiryRegarding: 'Contacted Via Form',
        description: ''
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleChangePhone = (n) => {
        console.log(n.length)
        if (n.length > 10 || n.length < 10) {
            setInvalidPhoneAlert('alert-form')
        } else {
            setInvalidPhoneAlert('alert-hide')
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (name == '' || phone == '' || desc == '') {
            if (name == '') {
                setNameAlert('alert-form')
            }
            if (phone == '') {
                setPhoneAlert('alert-form')
            } else if (phone.length != 10) {
                setInvalidPhoneAlert('alert-form')
            }
            if (desc == '') {
                setDescAlert('alert-form')
            }
        } else {
            setFormView("bg-light rounded p-4 hide-contact")
            setLoadingView("bg-light rounded loading")
            setThanksView("bg-light rounded p-4 h-100 thanks hide-all")

            const data = new FormData();
            data.append('timeStamp', formData.timeStamp);
            data.append('fullName', formData.fullName);
            data.append('phoneNumber', formData.phoneNumber);
            data.append('enquiryRegarding', formData.enquiryRegarding);
            data.append('description', formData.description);

            const Sheet_Url = "https://script.google.com/macros/s/AKfycbxwYzQbvbVPtR5N3VNzcTz8bIeCGfvhQqmhc4k9rYqpxp-sLfWU-40xTBdDJyvKvlbKCA/exec"

            try {
                const result = await fetch(Sheet_Url, {
                    method: 'POST',
                    body: data,
                    muteHttpExceptions: true,
                });

                if (result) {
                    setFormData({
                        timeStamp: new Date().toLocaleString(),
                        fullName: '',
                        phoneNumber: '',
                        enquiryRegarding: 'Received Through Contact Page',
                        description: ''
                    });
                    setStatus('SUCCESS')
                    setFormView("bg-light rounded p-4 hide-contact")
                    setLoadingView("bg-light rounded p-4 loading hide-loading")
                    setThanksView("bg-light rounded p-4 h-100 thanks get-all")
                }
            } catch (error) {
                console.log(error);
                setStatus('FAILED')
            }
        }
    };

    // Form Response
    const [formView, setFormView] = useState("bg-light rounded p-4")
    const [loadingView, setLoadingView] = useState("bg-light rounded loading hide-loading")
    const [thanksView, setThanksView] = useState("bg-light rounded thanks hide-all")

    return (
        <div>
            {/* Contact Us Banner Start */}
            <div className='about-banner'>
                <img src={AboutBanner} width={'100%'} height={'100%'}/>
                <div className='banner-path'>
                    <h1>Contact Us</h1>
                    <p><a href='/home'>Home</a> &#8594; Contact</p>
                </div>
            </div>
            {/* Contact Us Banner End */}

            {/* Contact Start */}
            <div className='grid grid-cols-2 enquiry-form'>
                <div className='div-1'>
                    <h1 className='enquiry-title'>Want to Know More ?</h1>
                    <div className={formView}>
                        <form onSubmit={handleSubmit}>
                            <div className='form-ele'>
                                <label>Full Name</label>
                                <input type='text' name='fullName' value={formData.fullName} onChange={(e) => {
                                    setName(e.target.value);
                                    setNameAlert('alert-hide');
                                    handleChange(e)
                                }} />
                                <p className={nameAlert}>Please Enter Your Name</p>
                            </div>
                            <div className='form-ele'>
                                <label>Phone Number</label>
                                <input type='number' name='phoneNumber' value={formData.phoneNumber} onChange={(e) => {
                                    setPhoneAlert('alert-hide');
                                    setPhone(e.target.value);
                                    handleChangePhone(e.target.value)
                                    handleChange(e)
                                }} />
                                <p className={phoneAlert}>Please Enter Your Phone Number</p>
                                <p className={invalidPhoneAlert}>Please Enter 10 Digit Valid Phone Number</p>
                            </div>
                            <div className='hide-timestamp'>
                                <label>Enquiry Regarding</label>
                                <input type="text" name="enquiryRegarding" value={formData.enquiryRegarding} onChange={(e) => {
                                    handleChange(e)
                                }} disabled />
                            </div>
                            <div className='form-ele'>
                                <label>Description</label>
                                <textarea type='text' name='description' value={formData.description} onChange={(e) => {
                                    setDescAlert('alert-hide');
                                    setDesc(e.target.value);
                                    handleChange(e)
                                }} rows={4}></textarea>
                                <p className={descAlert}>Describe Your Issue</p>
                            </div>
                            <div className='hide-timestamp'>
                                <label htmlFor="timeStamp">Time Stamp:</label>
                                <input type="Date" name="timeStamp" value={formData.timeStamp} onChange={(e) => {
                                    handleChange(e)
                                }} disabled />
                            </div>
                            <div className='form-ele'>
                                <button type='submit'>Submit</button>
                            </div>
                        </form>
                    </div>
                    <div className={loadingView}>
                        <HashLoader color='#007902' />
                        <h5>Submitting Your Response ...</h5>
                    </div>
                    <div className={thanksView}>
                        <h1>Thanks for Response</h1>
                        <img src={ValidCheck} alt='Check Arrow Image' width={'100%'} height={'100%'} />
                        <h5>Our Team will Reach You Soon.</h5>
                    </div>
                </div>
                <div className='div-2'>
                    <h1 className='enquiry-title'>Contact Us</h1>
                    <div className='contact-us-detail'>
                        <i className="fa fa-map-marker-alt me-3"></i>
                        <div>
                            <h1>Address</h1>
                            <p>5th Floor, Shantai Corner, Oppo. SB Patil School, Maske Vasti, Ravet, Pune - 412101</p>
                        </div>
                    </div>
                    <div className='contact-us-detail'>
                        <i className="fa fa-phone-alt me-3"></i>
                        <div>
                            <h1>Call Us</h1>
                            <p>+91 92260 75291</p>
                        </div> 
                    </div>
                    <div className='contact-us-detail'>
                        <i className="fa fa-envelope me-3"></i>
                        <div>
                            <h1>Email Us</h1>
                            <p>info@vtabuildcon.com</p>
                        </div> 
                    </div>
                    <div className='contact-us-detail-icons'>
                        <h3>Follow Us</h3>
                        <div className="social-icons">                          
                            <a className="btn btn-outline-light btn-social rounded-circle" href="https://wa.me/919226075291" target="_blank"><i className="fab fa-whatsapp me-3"></i></a>
                            <a className="btn btn-outline-light btn-social rounded-circle" href="https://www.instagram.com/vtabuildcon"><i className="fab fa-instagram me-3"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            {/* Contact End */}

            {/* Location Start */}
            <div className='map-location' id='maplocation'>
                <h1 className='location-title'>Location</h1>
                <div className='map-class'>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3780.2210960062475!2d73.74132127595877!3d18.65407196508119!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2bb00723e4487%3A0xb2726f2f3e64ac5b!2sVTA%20Buildcon!5e0!3m2!1sen!2sin!4v1734166055864!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
            {/* Location End */}
        </div>
    )
}

export default Contact;
